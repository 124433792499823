.profile__name {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.14;
  grid-area: "name";
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 880px) {
  .profile__name {
    font-size: 22px;
    line-height: 1;
    padding-right: 10px;
  }
}
