.square {
  max-width: 150px;
  min-height: 150px;
  width: 100%;
  position: absolute;
  top: 170px;
  left: -20px;
  background-color: #0278FE;
  z-index: -1;
}

@media screen and (max-width: 930px) {
  .square {
    position: relative;
    top: -250px;
  }
}

@media screen and (max-width: 450px) {
  .square {
    top: -100px;
    left: -70px;
  }
}

@media screen and (max-width: 400px) {
  .square {
    max-width: 100px;
    min-height: 100px;
    top: -40px;
    left: -80px;
  }
}
