.popup__input_type_error {
  color: rgba(255, 0, 0, 1);
  margin: 0;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  width: 100%;
  max-width: 358px;
  display: block;
  position: relative;
  left: 36px;
  bottom: 25px;
}

@media screen and (max-width: 552px) {
  .popup__input_type_error {
    left: 22px;
  }
}
