.footer__copyright {
  margin: 0;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1;
  color: #545454;
}

@media screen and (max-width: 880px) {
  .footer__copyright {
    font-size: 14px;
  }
}
