.popup__caption {
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  bottom: -22px;
}
