.footer {
  max-width: 880px;
  margin: 140px auto 0;
  padding-bottom: 60px;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 880px) {
  .footer {
    padding: 0 19px 36px;
    margin-top: 0;
  }
}
