.circle {
  position: absolute;
  top: 40px;
  max-width: 300px;
  min-height: 300px;
  border-radius: 100%;
  width: 100%;
  z-index: -1;
  border-top: 5px solid;
  border-left: 5px solid #000;
  border-right: 5px solid #000;
  border-bottom: 5px solid;
}

@media screen and (max-width: 930px) {
  .circle {
    position: relative;
    top: -60px;
  }
}

@media screen and (max-width: 450px) {
  .circle {
    max-width: 170px;
    min-height: 170px;
    top: -90px;
    left: 80px;
  }
}

@media screen and (max-width: 400px) {
  .circle {
    max-width: 150px;
    min-height: 150px;
    top: -60px;
    left: 40px;
  }
}
