.add-button {
  width: 100%;
  max-width: 150px;
  height: 50px;
  border: none;
  background-color: white;
  transition: opacity 0.2s linear;
  border-radius: 10px;
  color: #000;
  font-weight: 400;
}

.add-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 880px) {
  .add-button {
    max-width: 282px;
  }
}
