@keyframes rotation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.rotation-inverse {
  animation: rotation 20s reverse linear infinite;
}
