.info-tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 552px) {
    .info-tooltip {
      max-width: 280px;
    }
  }