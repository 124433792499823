.form-redirect {
  display: block;
  text-align: right;
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  margin: 15px 0 80px;
  padding: 0 271px;
}

@media screen and (max-width: 1280px) {
  .form-redirect {
    padding: 0 221px;
  }
}

@media screen and (max-width: 1135px) {
  .form-redirect {
    padding: 0 121px;
  }
}

@media screen and (max-width: 930px) {
  .form-redirect {
    text-align: center;
    padding: 0;
  }
}
