.profile {
  padding: 40px 0 50px;
  margin: auto;
  max-width: 880px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 880px) {
  .profile {
    flex-direction: column;
    padding: 42px 19px 36px;
  }
}
