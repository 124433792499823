.sign-form__intro {
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 930px) {
  .sign-form__intro {
    margin: 100px 0 0;
    flex-direction: column;
    max-height: 250px;
  }
}
