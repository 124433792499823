.sign-form__intro-paragraph {
  background-color: #fff;
  color: #000;
  margin: 0;
  max-width: 250px;
  padding: 20px;
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 1.2;
}

@media screen and (max-width: 930px) {
  .sign-form__intro-paragraph {
    position: absolute;
  }
}

@media screen and (max-width: 450px) {
  .sign-form__intro-paragraph {
    max-width: 200px;
  }
}
