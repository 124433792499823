.edit-button {
  width: 60px;
  height: 24px;
  border: none;
  background-color: #0278FE;
  grid-area: "button";
  margin-top: 7%;
  transition: opacity 0.2s linear;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
}

.edit-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

@media screen and (max-width: 880px) {
  .edit-button {
    margin-top: 0;
  }
}
