.info-tooltip__message {
    color: #000;
    margin: 0 0 60px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 1;
    text-align: center;
}

@media screen and (max-width: 552px) {
    .info-tooltip__message {
        font-size: 20px;
        margin: 0 18px 50px;
    }
  }