.profile__about-me {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1;
  grid-area: "about";
  margin: 16px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 880px) {
  .profile__about-me {
    font-size: 16px;
    grid-column: 1 / 3;
  }
}

@media screen and (max-width: 320px) {
  .profile__about-me {
    font-size: 14px;
    margin-top: 14px;
  }
}
