.popup__title {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
  color: #000;
  max-width: 358px;
  width: 100%;
  margin: 0 auto;
  padding: 34px 0 54px;
}

@media screen and (max-width: 552px) {
  .popup__title {
    max-width: 238px;
    font-size: 18px;
    padding: 25px 0 75px;
  }
}
