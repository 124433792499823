.header__link {
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.01;
    color: #fff;
    margin: 0 0 0 24px;
    align-self: center;
    text-decoration: none;
    transition: opacity 0.2s linear;
}

.header__link:hover {
    opacity: 0.6;
  }