.profile__wrapper {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  grid-template-areas: "name button" "about about";
  width: 100%;
  max-width: 415px;
  padding: 0 165px 0 30px;
}

@media screen and (max-width: 880px) {
  .profile__wrapper {
    margin: 0 auto;
    padding: 30px 0 36px;
    max-width: 282px;
    justify-content: center;
    text-align: center;
  }
}
