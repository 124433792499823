.header {
  max-width: 880px;
  padding: 45px 0 41px;
  margin: auto;
  border-bottom: 1.2px solid rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 880px) {
  .header {
    padding: 28px 27px 32px 27px;
  }
}

@media screen and (max-width: 552px) {
  .header {
    padding: 28px 30px 32px 30px;
    margin: 0 auto;
  }
}
