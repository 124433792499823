.card__title {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1;
  color: #000;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 25px 0 31px;
}
